import React from 'react'
import { graphql } from 'gatsby'
import Layout from '../components/layout'
import { HelmetDatoCms } from 'gatsby-source-datocms'

const ContactPage = ({ data }) => (
  <Layout>
    <>
      <HelmetDatoCms seo={data.datoCmsContact.seoMetaTags} />
      <div className="pa3 w-100">
        <h1 className="mt2">Contact</h1>
        <div className="w-100">
          <form name="contact" method="POST" data-netlify="true" data-netlify-recaptcha="true">
            <input type="hidden" name="form-name" value="contact" />
            <div className="mt3">
              <label className="db fw4 lh-copy f6" htmlFor="email">
                Email address
              </label>
              <input
                className="pa2 input-reset ba bg-transparent w-100 measure"
                type="email"
                name="email"
                id="email"
              />
            </div>
            <div className="mt3">
              <label className="db fw4 lh-copy f6" htmlFor="password">
                Enquiry
              </label>
              <textarea
                id="enquiry"
                name="enquiry"
                className="pa2 input-reset ba b--black bg-transparent w-100 measure"
              ></textarea>
            </div>
            <div data-netlify-recaptcha="true"></div>
            <div className="mt3">
              <input
                className="b ph3 pv2 input-reset ba b--black bg-transparent dim pointer f6"
                type="submit"
                value="Send message"
              />
            </div>
          </form>

          <ul className="mt3 list pa0 ma0">
            {data.datoCmsContact.email && (
              <li>
                <a
                  className="lh-solid dib link dim pv1 black-70"
                  href={`mailto:${data.datoCmsContact.email}`}
                >
                  Email - {data.datoCmsContact.email}
                </a>
              </li>
            )}
            {data.datoCmsContact.phone && (
              <li>
                <a
                  className="lh-solid dib link dim pv1 black-70"
                  href={`tel:${data.datoCmsContact.phone}`}
                >
                  Phone - {data.datoCmsContact.phone}
                </a>
              </li>
            )}
          </ul>
        </div>
      </div>
    </>
  </Layout>
)

export default ContactPage

export const query = graphql`
  query ContactQuery {
    datoCmsContact {
      email
      phone
      seoMetaTags {
        ...GatsbyDatoCmsSeoMetaTags
      }
    }
  }
`
